import React, { useEffect, useState } from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Slider() {
  const [revendaData, setRevendaData] = useState(null);

  useEffect(() => {
    const auth = JSON.parse(JSON.parse(localStorage.getItem('persist:american-burrs')).parceiro).cnpjRevenda;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fetchUser = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/revendas/cnpj/${auth}`, {
          method: 'GET',
        });
        const data = await response.json();
        setRevendaData(data);
        console.log('Dados retornados da API de revendas:', data);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };
    fetchUser();
  }, []);

  const handlePixBannerClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({
        top: 580,
        behavior: 'smooth'
      });
    }, 100);
  };

  return (
    <Container fluid="md" className="mb-5">
      <Row>
        <div className="d-none d-sm-block">
          <Carousel interval={3000}>
          <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {revendaData?.bannerExclusivo && (
              <Carousel.Item>
                <div className="d-none d-md-flex">
                  <Link to="/loja" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src="imagens/banner-exclusivo.png"
                      alt="Banner Exclusivo"
                    />
                  </Link>
                </div>
                <div className="d-flex d-md-none">
                  <Link to="/loja" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src="imagens/banner-exclusivo.png"
                      alt="Banner Exclusivo"
                    />
                  </Link>
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
        <div className="d-sm-none">
          <Carousel indicators={false} interval={3000}>
          <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados-mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados-mobile.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {revendaData?.bannerExclusivo && (
              <Carousel.Item>
                <div className="d-none d-md-flex">
                  <Link to="/loja" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src="imagens/banner-exclusivo.png"
                      alt="Banner Exclusivo"
                    />
                  </Link>
                </div>
                <div className="d-flex d-md-none">
                  <Link to="/loja" onClick={(e) => e.preventDefault()}>
                    <img
                      className="d-block w-100"
                      src="imagens/banner-exclusivo.png"
                      alt="Banner Exclusivo"
                    />
                  </Link>
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
