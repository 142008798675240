/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import { useSelector } from 'react-redux';
import Toggle from 'react-bootstrap-toggle';
import { toast } from 'react-toastify';
import ModalExcluir from './ModalExcluir/index';
import api from '../../../services/API';
import ModalEditar from './ModalEditar/index';
import { CSVLink } from 'react-csv';

const { SearchBar } = Search;

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`Redes${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          { label: 'Rede', key: 'nome' },
          { label: 'Região', key: 'regiao' },
          { label: 'Valor Ponto', key: 'valorPontoFormatado' },
          { label: 'Nº Lojas', key: 'numeroRevendas' },
          { label: 'Nº Vendedores Lojas', key: 'numeroParceiros' },
          { label: 'Total Vendas', key: 'totalVendasFormatado' },
          { label: 'Total Custo', key: 'totalCustoFormatado' },
          { label: 'Pontuação Total', key: 'totalPontosFormatado' },
          { label: 'Saldo de Pontos', key: 'saldoPontosFormatado' },
          { label: 'Última Pontuação', key: 'ultimaPontuacaoFormatada' },
          { label: 'Status', key: 'statusAtivo' }
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

export default function Redes() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: false,
      hidden: true,
    },
    {
      dataField: 'nome',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'regiao',
      text: 'Região',
      sort: true,
    },
    {
      dataField: 'valorPonto',
      text: 'Valor Ponto',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.valorPonto)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'numeroRevendas',
      text: 'Nº Lojas',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'numeroParceiros',
      text: 'Nº Vendedores Lojas',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalVendas',
      text: 'Total Vendas',
      formatter: (celula, valor) => {
        return Number(valor.totalVendas)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalCusto',
      text: 'Total Custo',
      formatter: (celula, valor) => {
        return Number(valor.totalCusto)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoTotal',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'ultimaPontuacao',
      text: 'Última Pontuação',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nome);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'nome',
      order: 'asc',
      sort: true,
    },
  ];

  const [data, setData] = useState([]);
  const [dataEditar, setDataEditar] = useState(null);
  const [buscando, setBuscando] = useState(false);
  const [id, setId] = useState('');
  const [rede, setRede] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [dataFormated, setDataFormated] = useState([]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    buscarRedes();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          nome: d.nome,
          regiao: d.regiao,
          valorPontoFormatado: Number(d.valorPonto).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).replace(/\u00a0/g, ' '),
          numeroRevendas: d.numeroRevendas,
          numeroParceiros: d.numeroParceiros,
          totalVendasFormatado: Number(d.totalVendas).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).replace(/\u00a0/g, ' '),
          totalCustoFormatado: Number(d.totalCusto).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).replace(/\u00a0/g, ' '),
          totalPontosFormatado: Number(d.totalPontos).toLocaleString('pt-BR'),
          saldoPontosFormatado: Number(d.saldoPontos).toLocaleString('pt-BR'),
          ultimaPontuacaoFormatada: d.ultimaPontuacao && d.ultimaPontuacao !== 0
            ? moment(d.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss')
            : '-',
          statusAtivo: d.ativo === 1 ? 'Ativo' : 'Inativo'
        }))
      );
    }
  }, [data]);

  const buscarRedes = async () => {
    setBuscando(true);

    let params = {
      completo: 1,
    };
    if (usuario.permissao !== 2) {
      params.ativo = 1;
    }
    const searchParams = new URLSearchParams(params);

    const resultado = await api.get(`/api/redes?${searchParams.toString()}`);
    if (resultado) {
      setData(resultado.data.redes);
    }
    setBuscando(false);
  };

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/redes/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Rede atualizada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setData(
      data.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, rede) => {
    setRede(rede);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setRede('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarRede = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Redes</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        rede={rede}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarRede={atualizarRede}
      />
    </Container>
  );
}
