import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import { toast } from 'react-toastify';
import Toggle from 'react-bootstrap-toggle';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';

const { SearchBar } = Search;

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`Premios${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          { label: 'Descrição', key: 'nome' },
          { label: 'Custo Total', key: 'custoTotal' },
          { label: 'Tipo', key: 'tipo' }
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

export default function Premios() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [premio, setPremio] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [dataFormated, setDataFormated] = useState([]);

  const columns = [
    { dataField: 'id', text: 'ID', sort: false, hidden: true },
    { dataField: 'nome', text: 'Descrição', sort: true },
    {
      dataField: 'custoTotal',
      text: 'Custo Total',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.custoTotal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
    {
      dataField: 'pontos',
      text: 'Pontos',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.pontos).toLocaleString('pt-BR');
      },
      hidden: true,
    },
    {
      dataField: 'pontosAlternativo',
      text: 'Pontos Alternativo',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.pontosAlternativo).toLocaleString('pt-BR');
      },
      hidden: true,
    },
    { dataField: 'tipo', text: 'Tipo', sort: true },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nome);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'nome',
      order: 'asc',
      sort: true,
    },
  ];

  const options = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    buscarPremios();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          nome: d.nome,
          custoTotal: Number(d.custoTotal)
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            .replace(/\u00a0/g, ' '),
          tipo: d.tipo
        }))
      );
    }
  }, [data]);

  const buscarPremios = async (currentPage = page, pageSize = sizePerPage) => {
    setBuscando(true);
    try {
      const resultado = await api.get('/api/premios', {
        params: {
          pagina: parseInt(currentPage),
          itensPorPagina: parseInt(pageSize)
        }
      });
      
      if (resultado?.data) {
        setData(resultado.data.premios);
        setTotalSize(resultado.data.totalItens);
        setPage(parseInt(currentPage));
      }
    } catch (error) {
      toast.error('Erro ao buscar prêmios');
    }
    setBuscando(false);
  };

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/premios/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Prêmio atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setData(
      data.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, premio) => {
    setPremio(premio);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setPremio('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = data.filter((d) => d.id === id);
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  const atualizarPremio = (dataUpdated) => {
    setData(
      data.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      setPage(page);
      setSizePerPage(sizePerPage);
      buscarPremios(page, sizePerPage);
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Prêmios</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                {...props.baseProps}
                remote={{ pagination: true }}
                onTableChange={handleTableChange}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        premio={premio}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarPremio={atualizarPremio}
      />
    </Container>
  );
}
