import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTableLoja from '../RemoteTableLoja';

const columns = [
  {
    dataField: 'codigo',
    text: 'Código',
  },
  {
    dataField: 'nome',
    text: 'Produto',
  },
  {
    dataField: 'valorPontosExibicao',
    text: 'Pontos',
    formatter: (celula, valor) => {
      return valor.valorPontosExibicao ? Number(valor.valorPontosExibicao).toLocaleString('pt-BR') : '0';
    },
    sort: true,
    sortFunc: sortFunc,
  },
  {
    dataField: 'ordem',
    text: 'Ordem',
    hidden: true,
    sort: true,
  },
];

export default function ListaPontos() {
  const cnpjRevenda = useSelector((state) => state.parceiro.cnpjRevenda);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <RemoteTableLoja
          dataId="id"
          url={`/api/produtos_revenda?cnpjRevenda=${cnpjRevenda}`}
          colunas={columns}
          filtros={{ cnpjRevenda: cnpjRevenda }}
          ordenacaoInicial={{ campo: 'ordem', direcao: 'asc' }}
        />
      </div>
    </Container>
  );
}
