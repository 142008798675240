import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function CadastrarProduto() {
  const cadastrarProduto = async (
    { codigo, nome, valorUnitario, valorPontos, valorPontosExibicao },
    { resetForm }
  ) => {
    if (!codigo) {
      toast.error('Código obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!nome) {
      toast.error('Nome obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!valorUnitario) {
      toast.error('Preço Unitário obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!valorPontos) {
      toast.error('Valor Pontos obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/produtos', {
      codigo,
      nome,
      valorUnitario,
      valorPontos,
      valorPontosExibicao: valorPontosExibicao || null,
    });

    if (resultado) {
      toast.success('Produto cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      resetForm();
    }
    setEnviando(false);
  };

  const [enviando, setEnviando] = useState(false);

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Produto American Burrs</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form className="form" onSubmit={cadastrarProduto}>
          <label htmlFor="codigo">Código</label>
          <Input
            id="codigo"
            name="codigo"
            type="text"
            placeholder="Código"
          ></Input>
          <label htmlFor="nome">Descrição</label>
          <Input
            id="nome"
            name="nome"
            type="text"
            placeholder="Descrição"
          ></Input>
          <label htmlFor="valorUnitario">Valor Unitário</label>
          <Input
            id="valorUnitario"
            name="valorUnitario"
            type="number"
            step="0.01"
            placeholder="Preço Unitário"
          ></Input>
          <label htmlFor="valorPontos">Valor em Pontos</label>
          <Input
            id="valorPontos"
            name="valorPontos"
            type="number"
            step="0.01"
            placeholder="Valor em Pontos"
          ></Input>
          <label htmlFor="valorPontosExibicao">Valor em Pontos (Exibição)</label>
          <Input
            id="valorPontosExibicao"
            name="valorPontosExibicao"
            type="number"
            step="0.01"
            placeholder="Valor em Pontos para Exibição"
          ></Input>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
          <button
            className="btn-secundario"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              historico.push('/painel/cadastrar/produto');
            }}
          >
            VOLTAR
          </button>
        </Form>
      </div>
    </Container>
  );
}
